import React, { useEffect } from 'react'
import aboutimg from "../Assets/about-hero.png"
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom'
// import dragon from '../Assets/dragonlabs.png'
// import tua from '../Assets/tua.png'
// import famtech from '../Assets/famtech.png'
// import next from '../Assets/next.png'
import target from '../Assets/target.png'
import coinstore from '../Assets/coinstore.png'
import coinscope from '../Assets/coinscope.png'
// import arbitrum from '../Assets/arbitrum.png'
import gynaika from '../Assets/gynaika.png'
import besa from '../Assets/besa.png'
import boundary from '../Assets/boundary.jpg'
import gameplay from '../Assets/gameplay.jpg'
// import pinksale from '../Assets/pinksale.png'
// import starknet from '../Assets/starknet.png'
// import optimism from '../Assets/optimism.png'
import layerzero from '../Assets/layerzero.png'
import gempad from '../Assets/gempad.png'
// import uniswap from '../Assets/uniswap-white.png'
import AnimatedSection from '../Components/AnimatedSection';

function About() {
    useEffect(() => {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2, // Adjust this threshold to control when the animation triggers.
      };
  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      }, observerOptions);
  
      const sections = document.querySelectorAll('.animated-section');
      sections.forEach((section) => observer.observe(section));
  
      return () => observer.disconnect();
    }, []);

  return (
    <>
    <AnimatedSection>
    <div className='container animated-section'>
        <div className='Default_Hero'>
          <h1 className='Default_Hero_text'>
          Racing Beyond the Stars
          </h1>
        </div>
      </div>

      <div className='container animated-section'>
      <div className='Hero_img'>
            <img src={aboutimg} alt='about hero'/>
            <p>
              We present SpeedSter, a groundbreaking Web3 game that catapults players into an expansive and procedurally generated universe, where they embark on a thrilling planetary space adventure. Immerse yourself in a world where exploration knows no bounds, resources are waiting to be harnessed, spaceships are crafted, and epic battles for territorial control await.
            </p>
     </div>
      </div>
      </AnimatedSection>

      <div className='container '>
        <div className='about_description_container'>
      <AnimatedSection>
      <div className='about_description animated-section'>
            <div className='about_box'>
                <img src={boundary} alt="" />
            </div>
            <div>
                <div className='role_title'>
                <h3>Pushing the Boundaries of  Web3 Technology</h3>
                </div>
                <div className='role_brief'>
                <p>SpeedSter is not your ordinary game—it takes advantage of the unique properties of Web3 technologies, including decentralized servers, non-fungible tokens (NFTs), and smart contracts, to offer an unparalleled and immersive gaming experience. The decentralized game world ensures true ownership of digital assets and enables players to trade spaceships, resources, and territories on the blockchain. </p>
                </div>
            </div>
          </div>
      </AnimatedSection>

      <AnimatedSection>
          <div className='about_description animated-section'>
            <div className='swap1'>
                <div className='role_title'>
                <h3>Embark on a Cosmic Journey: Gameplay Overview</h3>
                </div>
                <div className='role_brief'>
                <p>Begin your journey by creating your avatar and choosing a starting planet to explore. Each planet is a universe of its own, with distinctive environments, valuable resources, and formidable challenges. Venture into mining, farming, or trading with other players to gather resources, allowing you to upgrade your ships, establish colonies, and craft essential items. Engage with other explorers, engage in thrilling battles, and seize control of planets and territories. </p>
                </div>
            </div>
            <div className='about_box swap2'>
            <img src={gameplay} alt="" />
            </div>
          </div>
      </AnimatedSection>
          </div>
      </div>
      

      <AnimatedSection>
        <div className='container animated-section'>
            <div className='fullwidth_box'>
                <div className='fw_box_title'>
                    <h3>Tokenomics: SSB - Empowering the Universe</h3>
                </div>
                <div className='fw_box_text'>
                    <p>The backbone of SpeedSter's economy is the native token, SpeedSter Bucks (SSB). With a fixed supply of 1 billion tokens, SSB fuels in-game activities, such as purchasing resources, upgrading spaceships, and joining battles. Through blockchain-based NFTs, we release 10,000 unique spaceships, each with its own attributes, rarity, and distinctiveness. Players can own, trade, and stake these NFTs, elevating the gameplay to new heights.</p>
                </div>
                <div>
                <Link  className='btn' to='/tokenomics' >Learn More</Link>
                </div>
            </div>
        </div>
      </AnimatedSection>

      <AnimatedSection>
        <div className='container animated-section'>
            <div className='partners'>
                <div className='partners_title'>
                    <h3>
                        Our partners
                    </h3>
                </div>
                <div className='partners_list'>
                    <ul>
                        
                        {/* <li><img src={pinksale} alt="pinksale"/></li> */}
                        <li><img src={gynaika} alt="gynaika"/></li>
                        <li><img src={besa} alt="besa"/></li>
                        <li><img src={gempad} alt="gempad"/></li>
                        <li><img src={layerzero} alt="layerzero"/></li>
                        {/* <li><img src={uniswap} alt="uniswap"/></li> */}
                        
                        <li><img src={coinstore} alt="Coinstore logo"/></li>
                        <li><img src={coinscope} alt="Coinscopelogo"/></li>
                        <li><img src={target} alt="Target logo"/></li>
                        {/* <li><img src={arbitrum} alt="arbitrum logo"/></li> */}
                        {/* <li><img src={starknet} alt="starknet"/></li> */}
                        {/* <li><img src={optimism} alt="optimism"/></li> */}
                        
                        
                    </ul>
                </div>
            </div>
        </div>
        </AnimatedSection>

      <Footer/>
    </>
  )
}

export default About