import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import AnimatedSection from '../Components/AnimatedSection';

function Roadmap() {
    useEffect(() => {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2, // Adjust this threshold to control when the animation triggers.
      };
  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      }, observerOptions);
  
      const sections = document.querySelectorAll('.animated-section');
      sections.forEach((section) => observer.observe(section));
  
      return () => observer.disconnect();
    }, []);
  
  return (
    <>
    <AnimatedSection>
    <div className='container animated-section'>
        <div className='Default_Hero'>
          <h1 className='Default_Hero_text'>
          Roadmap
          </h1>
          <p>
          Our Roadmap, where we unveil our journey towards revolutionizing space exploration in the gaming world. Our roadmap outlines key milestones and timelines that will bring the universe of SpeedSter to life, captivating players with a seamless blend of high-speed racing and interstellar adventures.
          </p>
        </div>
    </div>
    </AnimatedSection>

    <AnimatedSection>
    <div className='container '>
        <div className='timeline'>
            <ul>
                <li className='timeline-content-deets'>
                    <div className='timeline-content'>
                        <h4 className='list-done timeline-range' >Q2 2023</h4>
                        <h3 className='timeline-title'>Project Planning & Research</h3>
                        <ul className='deets-list'>
                            <li><p>Define project scope, goals, and requirements</p></li>
                            <li><p>Conduct extensive market research and competitor analysis</p></li>
                            <li><p>Develop a comprehensive project plan and timeline</p></li>
                        </ul>
                    </div>
                </li>

                <li className='timeline-content-deets activetl'>
                    <div className='timeline-content'>
                        <h4 className='list-done timeline-range' >Q3 2023</h4>
                        <h3 className='timeline-title'>Smart Contract Development and Demo Game Launch</h3>
                        <ul className='deets-list'>
                            <li><p>Formulate monetization strategies and tokenomics</p></li>
                            <li><p>Develop and thoroughly test blockchain infrastructure and smart contracts</p></li>
                            <li><p>Build a captivating landing page and information hub about the project</p></li>
                            <li><p>Prepare for the public launch, including marketing, community engagement, and PR efforts</p></li>
                            <li><p>Integrate blockchain technology into the demo game mechanics and player interactions</p></li>
                            <li><p>Launch the demo game, monitor user engagement and gather feedback</p></li>
                            <li><p>Develop user account and wallet systems</p></li>
                        </ul>
                    </div>
                </li>

                <li className='timeline-content-deets'>
                    <div className='timeline-content'>
                        <h4 className='list-done timeline-range' >Q4 2023</h4>
                        <h3 className='timeline-title'>Main Game Design and Development</h3>
                        <ul className='deets-list'>
                            <li><p>Develop the main game mechanics, user interface, and game assets</p></li>
                            <li><p>Launch the marketplace for in-game resources and NFT spaceships</p></li>
                            <li><p>Integrate the main game with blockchain systems, ensuring decentralization and player ownership</p></li>
                            <li><p>Implement multiplayer functionality and social features to foster a vibrant community</p></li>
                            <li><p>Develop new monetization strategies and design a virtual economy within the main game</p></li>
                        </ul>
                    </div>
                </li>

                <li className='timeline-content-deets'>
                    <div className='timeline-content'>
                        <h4 className='list-done timeline-range' >Q1 2024</h4>
                        <h3 className='timeline-title'>Testing and Quality Assurance</h3>
                        <ul className='deets-list'>
                            <li><p>Conduct extensive internal testing and bug fixing</p></li>
                            <li><p>Initiate alpha and beta testing with select user groups</p></li>
                            <li><p>Implement user feedback and make necessary adjustments to enhance gameplay experience</p></li>
                        </ul>
                    </div>
                </li>

                <li className='timeline-content-deets'>
                    <div className='timeline-content'>
                        <h4 className='list-done timeline-range' >Q2 2024</h4>
                        <h3 className='timeline-title'>Deployment and Launch of Main Game</h3>
                        <ul className='deets-list'>
                            <li><p>Prepare for the public launch of the main game, including intensified marketing, community engagement, and PR efforts</p></li>
                            <li><p>Launch the main game to the eager players, monitoring user engagement and feedback closely</p></li>
                            <li><p>Continuously update and improve the game based on user feedback, ensuring a dynamic and evolving universe</p></li>
                        </ul>
                    </div>
                </li>

                <li className='timeline-content-deets'>
                    <div className='timeline-content'>
                        <h4 className='list-done timeline-range' >Post Launch</h4>
                        <h3 className='timeline-title'>Ongoing Support and Expansion</h3>
                        <ul className='deets-list'>
                            <li><p>SpeedSter is not just a game; it's an evolving universe. Beyond the initial launch, we are committed to providing regular updates, introducing new content, and expanding the cosmos with thrilling new adventures. Our vision is to make SpeedSter a continually enriching and captivating experience for players across the galaxy.</p></li>
                        </ul>
                    </div>
                </li>


            </ul>
        </div>

    </div>
    </AnimatedSection>


    <Footer/>
    </>
  )
}

export default Roadmap