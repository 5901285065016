import React, {useRef, useEffect, useState} from 'react'
import { useFrame, useLoader } from 'react-three-fiber';
// import { OrbitControls, Stars } from "@react-three/drei";
import { Stars } from "@react-three/drei";
import * as THREE from "three";

import AstrionMap from "../../Assets/textures/Astrion.webp"
import AstrionNormalMap from "../../Assets/textures/Nar Shaddaa (Bump 4k).webp"
import AstrionCloudMap from "../../Assets/textures/Nar Shaddaa (Clouds 4k).webp"

import { TextureLoader } from "three";

function Earth() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  const [solana, normalMap, cloudMap] = useLoader(
    TextureLoader,
    [AstrionMap, AstrionNormalMap, AstrionCloudMap ]
  );

  const earthRef = useRef();
  const cloudsRef = useRef();

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();

    earthRef.current.rotation.y = elapsedTime / 20;
    cloudsRef.current.rotation.y = elapsedTime / 20;
  });

  return (
    <>
    <ambientLight intensity={0.8} />
    <pointLight color="#f6f3ea" position={[2, 0, 1]} intensity={1.2}/>
    <Stars 
      radius={300} 
      dept={60} 
      count={20000} 
      factor={7} 
      saturation={0} 
      fade={true} 
    />
    <mesh ref={cloudsRef} position={isMobile ? [0, 0, 2.1]: [0, 0, 3]}>
      <sphereGeometry args={[1.005, 32, 32]} />
      <meshPhongMaterial 
        map={cloudMap} 
        opacity={0.4} 
        depthWrite={true} 
        transparent={true} 
        side={THREE.DoubleSide}
      />
    </mesh>

    <mesh ref={earthRef} position={isMobile ? [0, 0, 2.1]: [0, 0, 3]}>
        <sphereGeometry args={[1, 32, 32 ]} />
        <meshPhongMaterial specularMap={solana}/>
        <meshStandardMaterial 
          map={solana} 
          normalMap={normalMap} 
          metalness={0.1} 
          roughness={0.3}
        />
        {/* <OrbitControls 
          enableZoom={true} 
          enablePan={true} 
          enableRotate={true} 
          zoomSpeed={0.6} 
          panSpeed={0.5} 
          rotateSpeed={0.4} 
        /> */}
    </mesh>
    </>
  )
}

export default Earth