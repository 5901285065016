
import alan from '../Assets/Alan.jpg'

 const advisors = [
    {
        id: 1,
        image: alan,
        name: "Alan",
        role: "Portfolio Finance Director",
        alt: "Alan - Portfolio Finance Director",
        desc: "Alan is a Chartered Accountant and has enjoyed an extensive international finance career. FD and CFO experience in full financial responsibility for various groups of companies including managing group restructures and large digitisation projects. He is experienced in Audit management, fund raising, negotiating Bank and FX facilities. England.",
        linkedin: "https://www.linkedin.com/in/alanmccarthyharloworxltdoutsourcedfinancedirectorservices/"

    },
]

export default advisors

