import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import logo from '../Assets/logo.svg'
import hamburger from '../Assets/hamburger.svg'
import times from '../Assets/times.svg'


function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    // const [navbar, setNavbar] = useState(false) 
  return (
    <>
        <nav className='navbar'>
            <div className='navbarContainer'>
                <div className='navlogo'>
                    <Link to="/" className='logo-web'><img src={logo} alt="Speedster logo"/></Link>
                </div>
                <div>
                    <a href="https://speedsterspace.s3.eu-north-1.amazonaws.com/newbuild/index.html" target='_blank' rel="noreferrer" className='btn' style={{pointerEvents: "none"}}>Play Now</a>
                </div>
            </div>
        </nav>
        
        <div className='navigation'>
        <div className='hamburger' onClick={toggle}>
            {isOpen ?<img src={times} alt='Speedster Close'/>: <img src={hamburger} alt='Speedster Hamburger'/>  }    
        </div>
        </div>

        <div className={isOpen ? 'Menu' : 'Menu_close'} onClick={toggle}>
            <div className='Menu_container'>
                <ul>
                    <li><Link to="/" >Home</Link></li>
                    <li><Link to="/about" >About</Link></li>
                    <li><Link to="/tokenomics" >Tokenomics</Link></li>
                    <li><Link to="/roadmap" >Roadmap</Link></li>
                </ul>
            </div>
        </div>
        
    </>
  )
}

export default Navbar
