import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import tokenship from "../Assets/tokenship.png";
import rarity from "../Assets/rarity.png";
import coin from "../Assets/sbb.png";
import utility from "../Assets/utility.png";
import governance from "../Assets/governance.png";
import Charts from "../Components/Charts";
import AnimatedSection from "../Components/AnimatedSection";

// import { Pie } from 'react-chartjs-2'

function Tokenomics() {
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2, // Adjust this threshold to control when the animation triggers.
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          entry.target.classList.remove("in-view");
        }
      });
    }, observerOptions);

    const sections = document.querySelectorAll(".animated-section");
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <AnimatedSection>
        <div className="container animated-section">
          <div className="Default_Hero">
            <h1 className="Default_Hero_text">Fueling the Cosmic Journey</h1>
            <p>
              This is SpeedSter’s Tokenomics, where we unveil the heart and soul
              of our gaming economy. The SpeedSter Bucks (SSB) token lies at the
              core of our groundbreaking Web3 game, empowering players to
              explore, race, and conquer the vast universe that awaits them.
            </p>
          </div>
        </div>
      </AnimatedSection>

      <AnimatedSection>
        <div className="container animated-section">
          <div className="fullwidth_box_2">
            <div className="fullwidth_box_2_left">
              <div className="fw_box_title">
                <h3>NFTS</h3>
              </div>
              <div className="fw_box_text">
                <p>
                  The project will release a total of 1,001 NFTs, which
                  represent different spaceships in the game. Each NFT will be
                  unique and have its own set of attributes, such as speed,
                  firepower, and durability.
                </p>
                <p>
                  The NFTs will be distributed through a combination of random
                  drops and auctions. Some NFTs will also be reserved for
                  special events and promotions.
                </p>
              </div>
            </div>
            <div className="fullwidth_box_2_right mask-img">
              <img src={tokenship} alt="token ship" />
            </div>
          </div>
        </div>
      </AnimatedSection>

      <AnimatedSection>
        <div className="container animated-section">
          <div className="width_box_container">
            <div className="halfwidth_box">
              <div className="halfwidth_text">
                <h3>Token</h3>
                <p>
                  The project's native token will be called "SpeedSter Bucks"
                  (SSB). This token will be used for various in-game activities,
                  such as purchasing resources, upgrading spaceships, and
                  participating in battles.
                </p>
              </div>
              <div className="halfwidth_img">
                <img src={coin} alt="coin icon" />
              </div>
            </div>

            <div className="halfwidth_box">
              <div className="halfwidth_text">
                <h3>Rarity</h3>
                <p>
                  The NFTs will have different levels of rarity, with some being
                  more common than others. The rarest NFTs will have the most
                  powerful attributes and will be highly sought after by
                  players.
                </p>
              </div>
              <div className="halfwidth_img">
                <img src={rarity} alt="rarity icon" />
              </div>
            </div>
          </div>
        </div>
      </AnimatedSection>

      <AnimatedSection>
        <div className="container animated-section">
          <div className="fullwidth_box_2 supply">
            <div className="fullwidth_box_2_left">
              <div className="fw_box_title">
                <h3>Supply</h3>
              </div>
              <div className="fw_box_text">
                <p>
                  The total supply of SSB tokens will be fixed at 1 billion. The
                  tokens will be distributed as follows:
                </p>
              </div>
              <div className="fw-box_list">
                <ul>
                 
                  <li className="supply_chain">
                    <p className="supply_perc">25%</p>
                    <p className="supply_distro">Community Incentive</p>
                    <p className="supply_distro">(Vested)</p>
                  </li>
                  <li className="supply_chain">
                    <p className="supply_perc">15%</p>
                    <p className="supply_distro">Staking & Liquidity Mining</p>
                    <p className="supply_distro">(Vested)</p>
                  </li>
                  <li className="supply_chain">
                    <p className="supply_perc">14%</p>
                    <p className="supply_distro">Marketing & Partnerships</p>
                    <p className="supply_distro">(Vested)</p>
                  </li>
                  <li className="supply_chain">
                    <p className="supply_perc">9%</p>
                    <p className="supply_distro">Game Development</p>
                    <p className="supply_distro">(Vested)</p>
                  </li>
                  <li className="supply_chain">
                    <p className="supply_perc">8%</p>
                    <p className="supply_distro">Ecosystem Growth Fund</p>
                    <p className="supply_distro">(Vested)</p>
                  </li>
                  <li className="supply_chain">
                    <p className="supply_perc">15%</p>
                    <p className="supply_distro">Founding Team and Advisory</p>
                    <p className="supply_distro">(Vested)</p>
                  </li>
                  <li className="supply_chain">
                    <p className="supply_perc">5%</p>
                    <p className="supply_distro">Treasury Reserve</p>
                    <p className="supply_distro">(Vested)</p>
                  </li>
                  <li className="supply_chain">
                    <p className="supply_perc">9%</p>
                    <p className="supply_distro">DEX and CEX Listing</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="fullwidth_box_2_right ">
              <Charts />
            </div>
          </div>
        </div>
      </AnimatedSection>

      <AnimatedSection>
        <div className="container animated-section">
          <div className="width_box_container">
            <div className="halfwidth_box">
              <div className="halfwidth_text">
                <h3>Utility</h3>
                <p>
                  The SSB token serves as the primary currency within the
                  SpeedSter ecosystem, offering players a multitude of utility
                  functions:
                </p>
                <ul className="show_list_style">
                  <li>
                    <p>Purchasing resources</p>
                  </li>
                  <li>
                    <p>Upgrading spaceships</p>
                  </li>
                  <li>
                    <p>Participating in battles</p>
                  </li>
                  <li>
                    <p>Staking</p>
                  </li>
                  <li>
                    <p>In-game Fees</p>
                  </li>
                </ul>
              </div>
              <div className="halfwidth_img">
                <img src={utility} alt="utility " />
              </div>
            </div>

            <div className="halfwidth_box">
              <div className="halfwidth_text">
                <h3>Community Governance</h3>
                <p>
                  At SpeedSter, we believe in fostering a vibrant and engaged
                  community. The decentralized governance structure allows SSB
                  token holders to actively participate in shaping the game's
                  future. Propose, vote, and decide on the direction of
                  SpeedSter, ensuring that the voices of players are heard and
                  respected.
                </p>
              </div>
              <div className="halfwidth_img">
                <img src={governance} alt="governance" />
              </div>
            </div>
          </div>
        </div>
      </AnimatedSection>

      <AnimatedSection>
        <div className="container animated-section">
          <div className="Default_Hero">
            <h1 className="Default_Hero_text">
              Join us on this extraordinary journey, explore the uncharted
              cosmos, race beyond the stars, and become a legendary pioneer in
              the universe of SpeedSter.
            </h1>
          </div>
        </div>
      </AnimatedSection>

      <Footer />
    </>
  );
}

export default Tokenomics;
