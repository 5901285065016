import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './Styles/main.css';
import './Styles/responsive.css';
import ScrollToTop from './Utils/Scrolltotop';

import Home from './Pages/Home';
import Error404 from './Pages/Error404';
import Navbar from './Components/Navbar';

import './Fonts/Wulkan/stylesheet.css';
import './Fonts/galaxy/stylesheet.css';
import About from './Pages/About';
import Tokenomics from './Pages/Tokenomics';
import Roadmap from './Pages/Roadmap';
import News from './Pages/News';
import Team from './Pages/Team';

function SmallScreenApp() {
  return (
    <div className='Default_Hero'>
      <h1 className='Default_Hero_text'>
        Please Switch to a screen larger than this.
      </h1>
    </div>
  );
}

function App() {
  const isSmallScreen = window.innerWidth < 350;

  return (
    <div>
      {isSmallScreen ? (
        <SmallScreenApp />
      ) : (
        <Router>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/tokenomics' element={<Tokenomics />} />
            <Route path='/roadmap' element={<Roadmap />} />
            <Route path='/news' element={<News />} />
            <Route path='/team' element={<Team />} />
            <Route path='*' element={<Error404 />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
