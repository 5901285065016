import nvzv from "../Assets/nvzv.jpg";
import ben from "../Assets/ben.jpg";
import wisdom from "../Assets/wisdom.jpg";
import lex from "../Assets/lex.jpg";
import mykel from "../Assets/mykel.jpg";


const team = [
  {
    id: 1,
    image: wisdom,
    name: "Terkion",
    role: "Game Developer",
    alt: "Wisdom - Game Developer",
    desc: "Game Developer at BRN Metaverse, the best MMORPG game for web3 and the metaverse. Istanbul.",
    linkedin: "https://www.linkedin.com/in/wisdom-nnadi-4962461a0/",
  },
  {
    id: 2,
    image: ben,
    name: "Ben",
    role: "Partnership Lead",
    alt: "Ben - Partnership Lead",
    desc: "Web3 Enthusiasts, Community Manager and Ex Business Development Specialist, Binance Africa.",
    linkedin: "https://www.speedster.space",
  },
 {
    id: 3,
    image: nvzv,
    name: "Nvzv",
    role: "Blockchain Developer",
    alt: "Chinaza - Blockchain Developer",
    desc: "Technical and Engineering Lead at Scorefam, a risk-profiled sports gaming platform on the blockchain. British Virgin Islands.",
    linkedin: "https://www.linkedin.com/in/chinaza-o-h-bb81bb97/",
  },
  {
    id: 4,
    image: lex,
    name: "Lexion.éth",
    role: "Professional Moderator",
    alt: "Lex - Professional Moderator",
    desc: "Professional Discord/Telegram moderator and community manager with over three years with extensive experience in business development management.",
    linkedin: "https://www.linkedin.com/in/lexion-%C3%A9th%F0%9F%9F%A1%F0%9F%9F%A1-2a75b2238?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    id: 5,
    image: mykel,
    name: "Mykelblvck",
    role: "Professional Moderator",
    alt: "Mykelblvck - Professional Moderator",
    desc: "Professional Social Media manager and community manager with over two years experience with extensive experience working for several blockchain projects ranging from DeFi to NFT Gaming.",
    linkedin: "https://www.linkedin.com/me?trk=p_mwlite_feed_updates-secondary_nav",
  },
];

export default team;
