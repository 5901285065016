import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../Assets/logo.svg'
import twitter from '../Assets/mdi_twitter.svg'
import discord from '../Assets/ic_baseline-discord.svg'
import telegram from '../Assets/telegram.svg'
import medium from '../Assets/medium.svg'

function Footer() {
  const pdfURL = '/pdf/Speedster_Whitepaper.pdf';
  return (
    <div className='container footer_div'>
      <div className='footer_container'>
      <div className='footer'>
        <div className='left'>
          <Link to="/" ><img src={logo} alt='speedster logo'/> </Link>
          <ul className='socials'>
            <li><a href="https://twitter.com/SpeedSterspace" target='_blank' rel='noreferrer'> <img src={twitter} alt='twitter icon' /></a></li>
            <li><a href="https://discord.gg/SH5q2Nm6" target='_blank' rel='noreferrer'><img src={discord} alt='dscord icon' /></a></li>
            <li><a href="https://speedsterspace.medium.com/" target='_blank' rel='noreferrer'><img src={medium} alt='medium icon' /></a></li>
            <li><a href="https://t.me/+b2m2PunT7illM2U8" target='_blank' rel='noreferrer'><img src={telegram} alt='telegram icon' /></a></li>
          </ul>
        </div>
        <div className='right'>
          <div className='footer_menu'>
            <ul>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/tokenomics">Tokenomics</Link></li>
              <li><Link to="/roadmap">Roadmap</Link></li>
            </ul>

            <ul>
              <li><Link to="/team">Team</Link></li>
              <li><Link to={pdfURL} target="_blank" rel="noopener noreferrer">Whitepaper</Link></li> 
              <li><Link to="/news">News</Link></li>
              {/* <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/terms">Terms</Link></li> */}
            </ul>

          </div>
        </div>
      </div>
      <div className='copy'>
        <p>© 2024 Speedster</p>
      </div>
      </div>
    </div>
  )
}

export default Footer