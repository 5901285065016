import React from 'react'
import Footer from '../Components/Footer'

function News() {
  return (
    <>
    <div className='container'>
        <div className='Default_Hero'>
          <h1 className='Default_Hero_text'>
          News! News!! News!!! 📰
          </h1>
          <p>News & announcements will be updated here, be sure to come back here often.</p> 
        </div>
      </div>


      <div className='empty_space'>

      </div>



      <Footer/>
    </>
  )
}

export default News