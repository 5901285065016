import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Community', 'Staking Rewards', 'Marketing & Partnerships', 'Game Development', 'Team', 'Presale & Liquidity', 'CEX Listing', 'Reserve'],
  datasets: [
    {
     
      data: [7, 14, 8, 12, 5, 40, 9, 5],
      backgroundColor: [
        '#A028FF',
        '#BA28FF',
        '#DCAAEE',
        '#FDABEF',
        '#C773E4',
        '#9123AD',
        '#D40BC0',
        '#7A0ABB',
      ],
      borderColor: [
        '#CB36FF',
        '#CB36FF',
        '#CB36FF',
        '#CB36FF',
        '#CB36FF',
        '#CB36FF',
        '#CB36FF',
        '#CB36FF',
      ],
      borderWidth: 0.6,
    },
  ],
};

export default function Charts() {
  const options = {
    plugins: {
      legend: {
        display: false, // Set to false to hide the legend
      },
    },
  };

  return <Doughnut data={data}  options={options}/>;
}
