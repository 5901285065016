import React, { Suspense } from 'react'
import styled from "styled-components"
import { Canvas } from "@react-three/fiber"
import Earth from './Earthy/Earth';

const CanvasContainer = styled.div`
    width: 100%;
    height: 100vh;
`;

function Globe() {
  return (
    <CanvasContainer>
        <div className='container'>
        <div className='Hero hero_small'>
          <h1 className='Hero_text'>
            Discover The Thrilling World of Speedster on
          </h1>
          <h1 className='Hero_text'>
             SOLANAR 
          </h1>
          <p className='Hero_small_text'> a space with cross interoperability at heart </p>
        </div>
     
      </div>
        <Canvas>
            <Suspense fallback={null}>
                <Earth/>
            </Suspense>
        </Canvas>
    </CanvasContainer>
  )
}

export default Globe