import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import linkedin from '../Assets/mdi_linkedin.svg'
import team from '../Data/team';
import advisors from '../Data/advisors';
import AnimatedSection from '../Components/AnimatedSection';

function Team() {
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2, // Adjust this threshold to control when the animation triggers.
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        } else {
          entry.target.classList.remove('in-view');
        }
      });
    }, observerOptions);

    const sections = document.querySelectorAll('.animated-section');
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  return (
    <>
      
      {/* CORE TEAM */}
    <AnimatedSection>
    <div className='container animated-section'>
        <div className='Default_Hero'>
          <h1 className='Default_Hero_text'>
          Core Team
          </h1>
        </div>
      </div>
      </AnimatedSection>

      <AnimatedSection>
      <div className='container '>
        <div className='team'>
          <ul>
            {team.map((item, i) => {
              return (

                <li className='team-member' key={i}>
                <div className='team-deets'>
                  <img src={item.image} alt={item.alt}/>
                  <div className='team-deets-header'>
                    <div className='team-deets-header-left'>
                      <h3 className='member-name'>{item.name}</h3>
                    </div>
                    <div className='team-deets-header-right'>
                      <a href={item.linkedin} className='icon-btn' target='_blank' rel="noreferrer"><img src={linkedin} alt='linkedin logo'/></a>
                    </div>
                  </div>
                  <p className='member-role'>{item.role}</p>
                  <p className='desc'>{item.desc}</p>
                </div>
              </li>
              )
            })}
            

          </ul>
        </div>
      </div>
      </AnimatedSection>


            {/* ADVISORS */}

      <AnimatedSection>
      <div className='container animated-section'>
          <div className='Default_Hero'>
            <h1 className='Default_Hero_text'>
            Advisors
            </h1>
          </div>
        </div>
      </AnimatedSection>

      <AnimatedSection>
      <div className='container '>
        <div className='team'>
          <ul className='gcenter'>
            {advisors.map((item, i) => {
              return (

                <li className='team-member' key={i}>
                <div className='team-deets'>
                  <img src={item.image} alt={item.alt}/>
                  <div className='team-deets-header'>
                    <div className='team-deets-header-left'>
                      <h3 className='member-name'>{item.name}</h3>
                    </div>
                    <div className='team-deets-header-right'>
                      <a href={item.linkedin} className='icon-btn' target='_blank' rel="noreferrer"><img src={linkedin} alt='linkedin logo'/></a>
                    </div>
                  </div>
                  <p className='member-role'>{item.role}</p>
                  <p className='desc'>{item.desc}</p>
                </div>
              </li>
              )
            })}
          </ul>
        </div>
      </div>
      </AnimatedSection>


      <Footer/>
    </>
  )
}

export default Team